var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"orders-container"},[_c('b-alert',{attrs:{"variant":_vm.alert.variant,"dismissible":"","fade":""},model:{value:(_vm.alert.model),callback:function ($$v) {_vm.$set(_vm.alert, "model", $$v)},expression:"alert.model"}},[_vm._v(" "+_vm._s(_vm.alert.text)+" ")]),(!_vm.isRbAdmin)?_c('h1',[_vm._v("Orders")]):_vm._e(),_c('b-row',{staticClass:"container-search"},[_c('GenericSearch',{staticClass:"mr-2",attrs:{"value":_vm.form.input},on:{"updateSearch":_vm.updateSearch}}),_c('BaseButton',{directives:[{name:"b-toggle",rawName:"v-b-toggle.advanced-search",modifiers:{"advanced-search":true}}],attrs:{"variant":"secondary","size":"md"}},[_vm._v(" Advanced Search ")]),_c('div',{staticClass:"flex xs:flex-wrap gap-2 xs:ml-0 xs:mt-4 lg:mt-0 lg:ml-2"},[(_vm.canCreateCashSales)?_c('CreateCashSaleModal',{attrs:{"size":"sm"}}):_vm._e(),(_vm.showCreateOrderV2)?_c('CreateOrderV2',{on:{"triggerRefresh":_vm.triggerRefresh}}):_vm._e()],1)],1),_c('b-collapse',{attrs:{"id":"advanced-search"}},[_c('b-row',{staticClass:"container-search"},[_c('b-col',[_c('OrderFilters',{attrs:{"defaultStartDateTime":_vm.form.filter.startDateTime,"defaultEndDateTime":_vm.form.filter.endDateTime},on:{"orderFilterChanged":_vm.onOrderFilterChanged}})],1)],1)],1),_c('div',{staticClass:"overflow-x-hidden"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"table-overflow"},[_c('GenericTable',{attrs:{"columns":_vm.columns,"data":_vm.data,"actions":true,"loading":_vm.loading,"pagination":_vm.pagination,"totalName":_vm.totalName},on:{"updateCurrentPage":_vm.updateCurrentPage,"sort":_vm.sort},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":{ path: ("/order/" + (row.uuid)) }}},[_c('p',{staticClass:"text-gray-900 underline text-left"},[_vm._v(_vm._s(row.id))])])]}},{key:"amountPaidCents",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.amountPaidCents))])]}},{key:"numTickets",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-right"},[_vm._v(_vm._s(row.numTickets))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status === 'ACTIVE')?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Active")]):(row.status === 'INACTIVE')?_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v("Inactive")]):(row.status === 'PENDING')?_c('b-badge',{attrs:{"variant":"light"}},[_vm._v("Pending")]):(row.status === 'VOID')?_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v("Void")]):(row.status === 'FAILED')?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Error")]):_vm._e()]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"action-button"},[_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('edit-order-' + row.id),expression:"'edit-order-' + row.id"}]},[_c('i',{staticClass:"fa-regular fa-pen-to-square"})]),_c('EditOrderModalV2',{attrs:{"modalId":row.id,"orderId":row.uuid},on:{"orderUpdated":_vm.refreshTable}})],1)]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }